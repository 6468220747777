<template>
    <div class="container-window mt-3 mr-3 border bd-e4 px-3">
        <HeaderWindow :title="'Thông tin sức khoẻ'" :care_team_doctor="care_team_doctor" @onClose="onClose" :type="'waiting-profile-details'" :disease="disease" @postCareTeamsAccept="postCareTeamsAccept" @postCareTeamsReject="postCareTeamsReject"></HeaderWindow>
        <div class="bg-white pt-3 pb-3">
            <PersonProfile v-if="disease" :person="disease && disease.person" :disease="disease" :type="'waiting-profile-details'"></PersonProfile>
        </div>
        <div class="mt-3 px-3">
            <InformationForm :disease="disease" @onShowProfileHistory="onShowProfileHistory" :type="'waiting-profile-details'" :care_team_doctor="care_team_doctor"></InformationForm>
            <ParameterMonitoring v-if="disease" @openSetting="onShowModalSetting" :disease="disease" :type="'waiting-profile-details'"></ParameterMonitoring>
        </div>
        <ModalParameterSetting v-if="disease && person" :person_diseases_id="disease.id" :person_id="person.id"></ModalParameterSetting>
    </div>
</template>
<script>
import appUtils from '../../utils/appUtils.js'
import InformationForm from '../../components/Diseases/InfomationForm.vue'
import HeaderWindow from '../../components/Diseases/HeaderWindow.vue'
import ParameterMonitoring from '../../components/Diseases/ParameterMonitoring.vue'
import ModalParameterSetting from '../../components/Diseases/ModalParameterSetting.vue'
import PersonProfile from '../../components/Diseases/PersonProfile'
export default {

  name: 'WaitingProfileDetails',
  components: { HeaderWindow, InformationForm, ParameterMonitoring, ModalParameterSetting, PersonProfile },
  data () {
    return {
      disease: null,
      person: null,
      user: null,
      doctor_id: null,
      care_team_doctor: null
    }
  },
  mounted () {
    let self = this
    self.user = appUtils.getLocalUser()
    self.getPersonsDiseases()
  },
  methods: {
    onClose () {
      this.$router.push({ path: `/doctor/waiting-list` }).catch(_ => {})
    },
    onShowModalSetting (show) {
      window.$('#modal-parameter-setting').modal(show ? 'show' : 'hide')
    },
    async getPersonsDiseases () {
      let self = this
      try {
        await self.$rf.getRequest('DoctorRequest').getPersonsDiseasesDetail(self.$route?.params?.id).then(res => {
          self.disease = res.data
          self.person = res.data.person || null
          if (res?.data?.care_team?.care_team_doctor?.length) {
            if (appUtils.isDoctor()) {
              self.care_team_doctor = res?.data?.care_team?.care_team_doctor?.find(ctdr => appUtils?.getLocalUser()?.doctor?.id === ctdr.doctor_id && ctdr.is_accepted !== 1)
            }
          }
        }).then(() => {})
      } catch (e) {
        console.log(e)
      }
    },
    onShowProfileHistory (show) {
      let self = this
      self.$router.push({path: `/doctor/diseases/${self.$route?.params?.id}/profile-history`})
    },
    async postCareTeamsAccept () {
      let self = this
      let clinic_id = this.$clinicId
      let params = {
        clinic_id: clinic_id,
        care_team_id: self.care_team_doctor.care_team_id
      }
      try {
        if (!!self.care_team_doctor && self.care_team_doctor.id) {
          await self.$rf.getRequest('DoctorRequest').postCareTeamsAcceptV2(params).then(res => {
            self.$router.push({path: `/doctor/diseases/${self.$route?.params?.id}`})
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    async postCareTeamsReject () {
      let self = this
      try {
        if (self.care_team_doctor) {
          await self.$rf.getRequest('DoctorRequest').postCareTeamsReject(self.care_team_doctor.care_team_id).then(res => {
            self.$router.push({path: `/doctor/waiting-list`})
          })
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
<style lang="css" scoped>
</style>