<template>
  <ListDiseases :type="'pending'"></ListDiseases>
</template>
<script>
import ListDiseases from '../../components/Diseases/ListDiseases'
export default {
  components: { ListDiseases },
  name: 'WaitingList',
  data () {
    return {
    }
  }
}
</script>
<style scoped>
</style>